<template>
                      <ul class="hedlogo-ul">
                          <li class="headlogo-li" @click="goToDashboard()">
                              <a href="#" class="logo-hed">
                                  <div class="logo-sec">
                                        <img class="app-logo" :src="logoSrc()" :width="logoWidth"   alt=""/>
                                  </div>
                              </a>
                          </li>
                          <li class="headlogo-li">
                              <a href="#" class="video-hed">
                                  <div class="video-icon-sec" @click="goToNewStreamingPage()">
                                      <img :src="logoVideoSrc()" class="app-logo">
                                  </div>
                              </a>
                          </li>
                          <li class="headlogo-li dropdown"  @click="mouseOverFun">
                              <div class="profile-img-sec dropbtn">
                                  <a href="#" class="profl-hed">
                                      <img :src="user.avatar_full_path" class="net-profile-img">
                                  </a>
                              </div>
                              <div class="dropdown-content profile-menusec" v-show="active">
                                  <a  v-for="(item, i) in items " :key="i" @click="menuActionHandler(item)" href="#" class="profile-menu">
                                    <i :class="item.icon"></i>{{ item.text }}</a>
                                </div>
                          </li>
                          <div id="Mysider_notification" v-show="showNotifications"  class="sidber-notification" v-bind:style="{width: '250px'}">
                                          <div class="mostrecnt_contac">
                                              <a href="javascript:void(0)" class="closebtn" @click="CloseNotification()" >Notification <span>&times;</span></a>
                                              <div class="mostrecnt-contsec">
                                                  <p></p>
                                              </div>
                                          </div>
                          </div>
                            <div id="Mysider_contact" v-show="showAddressBook" class="sidber-notification" v-bind:style="{width: '250px'}">
                                <div class="mostrecnt_contac">
                                  <a href="javascript:void(0)" class="closebtn" @click="toggleAddressBooksclose()">Most Recent <span>&times;</span></a>
                                          <div class="mostrecnt-contsec">
                                                  <p></p>
                                          </div>
                                  </div>
                          </div>
                      </ul>



</template>
<script lang="js">
import api from "@/api.js";
import baseimg from "@/baseimg.js";
import { mapState } from "vuex";
    export default({
    props: ["user", "newNotification"],
    components: {

    },
    data() {
    return {
      active:false,

    };
  },
    watch: {
      user: {
          handler(value) {
              if (!value.id) {
                  return;
              }
              const userauth = localStorage.getItem("userId");
              if(userauth){
                if(userauth ==this.$route.params["id"]){
                  // this.isStreamer = true;
                }else{
                  // this.isStreamer = false;
                }
              }else{
              }
            },
          deep: true,
          immediate: true
      },

    },

    computed:{
    items() {
            const items = [
                { name: "userMenu", text: "", icon: "fa-solid fa-user" },
                /*{this.user.name
                    name: "notifications",
                    text: "Notifications",
                    icon: "fa-solid fa-bell"
                },
                { name: "contacts", text: "Contacts", icon: "fa-regular fa-address-book" },
               /* {
                    name: "people",
                    text: "People",
                    icon: "fa-solid fa-users"
                },*/

                { name: "settings", text: "", icon: "fa-solid fa-gear" },
                { name: "logout", text: "", icon: "fa-sharp fa-solid fa-right-from-bracket" }
            ];
            return items;
        },

    },
    mounted() {

    },
    created() {
  },
    methods:{

        async toggleNotifications() {
            this.showAddressBook = false;
            this.showNotifications = true;
            this.active=false;
            await api.notification.markAsRead();
          // this.getUser();
        },
        CloseNotification(){
        this.showNotifications= false;
        this.active=true;
        },
        toggleAddressBooksclose(){
        this.showAddressBook= false;
        this.active=true;
        },
        async toggleAddressBooks() {
            this.showNotifications = false;
            this.showAddressBook = true;
            this.active= false;
        },
        showListview(){
          this.showlist= !this.showlist;
        },

        menuActionHandler(item) {
            switch (item.name) {
                case "userMenu":
                    location.href = `/user/${this.user.id}`;
                    break;
                case "notifications":
                    this.toggleNotifications();
                    break;
                case "contacts":
                    this.toggleAddressBooks();
                    break;
                case "settings":
                    location.href = "/settings";
                    break;
                //case "people":
                //    this.goToHome();
                //    break;
              /**   case "randomChat":
                    location.href = "/video-chat";
                    break;
                **/
                case "settings":
                    location.href = "/settings";
                    break;
                /**
                case "liveVideoId":
                    this.openLiveVideoIdDialog();
                    break;
                    */
                case "logout":
                    this.logout();
                    break;
            }
        },
        mouseOverFun(){
            if(this.active){
            this.active =false;
            }else{
              this.active=true;
            }
        },
        mouseOverout: function(){
             // this.active =false;
        },
        goToHome() {
        location.href = "/";
        },
        goToDashboard() {
        location.href = "/dashboard";
        },
        logoSrc() {
            return "/images/logo/net-app-log-white.png";
        },
        logoVideoSrc() {
                return "/images/logo/video-camera-white.png";
        },
        goToNewStreamingPage(){
          location.href = "/new-streaming";
        },

        async logout() {
          const userauth = localStorage.getItem("userId");

            try {
                await api.user.updateToOffline(userauth);
            } finally {
                if (this.user.id) {
                    await api.auth.logout();
                }
                localStorage.removeItem("userId");
                localStorage.removeItem("isLoggedIn");
                location.href = "/login";
                this.$router.push({ name: "Login" });
            }
        },
    }
    });
</script>